'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.focus.factory:FocusManager

 # @description

###
angular
  .module 'mundoAdmin.tasks'
  .factory 'FocusManager',[
    'MundoDefaultManager'
    '$state'
    '$timeout'
    '$rootScope'
    'DispatchUnitTypesManager'
    'DispatchUnitsManager'
    '$translate'
    'MundoMap'
    'UiHelpers'
    'ModelHelpers'
    'UnitsManager'
    '_'
    'Restangular'
    (
      MundoDefaultManager
      $state
      $timeout
      $rootScope
      DispatchUnitTypesManager
      DispatchUnitsManager
      $translate
      MundoMap
      UiHelpers
      ModelHelpers
      UnitsManager
      _
      Restangular
    )->
      FocusManagerBase = new MundoDefaultManager()

      FocusManagerBase.setUrl('lpa/services/focus')
      FocusManagerBase.setAutoTenant()
      FocusManagerBase.setNewObject([
        'locationLongitude',
        'locationLatitude',
        'locationDescription',
        'description',
        'contactPhoneNumber',
        'contactName',
        'externalId',
        'closedAt',
        'type'
      ])
      FocusManagerBase.setActions(['create', 'list', 'print', 'search', 'update'])
      FocusManagerBase.setSearchFields(['externalId'])
      FocusManagerBase.editPermission = 'manage all MundoMosaLpaDispatchingBundle:LpaPoiOrder entities'
      FocusManagerBase.viewPermission = 'view all MundoMosaLpaDispatchingBundle:LpaPoiOrder entities'
      FocusManagerBase.defaultQuery.sort = 'externalId'

      FocusManagerBase.getListSchema = ->
        [
          key: 'externalId'
          title: 'datatable.label.taskId'
          sort: 'externalId'
        ,
          key: 'createdAt'
          title: 'datatable.label.created.at'
          sort: 'createdAt'
          type: 'dateTime'
        ,
          key: 'closedAt'
          title: 'datatable.label.closed.at'
          sort: 'closedAt'
          type: 'dateTime'
          hideInList: true
        ,
          key: 'locationDescription'
          title: 'Location description'
          hideInList: true
        ,
          key: 'coordinates'
          title: 'Location coordinates'
          hideInList: true
        ,
          key: 'contactName'
          title: 'Contact name'
          hideInList: true
        ,
          key: 'contactPhoneNumber'
          title: 'Contact phone number'
          hideInList: true
        ,
          key: 'description'
          title: 'Description'
        ]

      FocusManagerBase.getNewForm = ->
        #not needed rn
        # checkIfBusy = (promise) ->
        #   promise.then (units) ->
        #     # probably wrong but the relations are currently difficult to research
        #     _.each units, (unit, index) ->
        #       unit.task? && unit.task.incident? && unit.task.incident.id? units[index].label += ' (busy)'
        #   promise.$object

        form = [
          key: 'externalId'
          type: 'input'
          name: 'externalId'
          templateOptions:
            label: $translate.instant('app.dispatch.task')
            placeholder: $translate.instant('app.dispatch.task')
            required: true
          controller: ($scope) ->
            $scope.to.loading = FocusManagerBase.getLpaPoiOrderCount().then (result) ->
              index = parseInt(result) + 1
              index = index.toString().padStart(7, 0)
              prefix = ''
              prefix = $rootScope.user.activeUserContext.tenant.lpaPoiOrderPrefix if $rootScope.user.activeUserContext.tenant.lpaPoiOrderPrefix?
              $scope.model.externalId = prefix + index
        ,
          key: 'locationDescription'
          type: 'input'
          name: 'locationDescription'
          templateOptions:
            label: $translate.instant('app.dispatch.location')
            placeholder: $translate.instant('app.dispatch.location')
            required: true
        ,
          key: 'contactPhoneNumber'
          type: 'input'
          name: 'contactPhoneNumber'
          templateOptions:
            label: $translate.instant('app.dispatch.contact-number')
            placeholder: $translate.instant('app.dispatch.contact-number')
            required: true
        ,
          key: 'contactName'
          type: 'input'
          name: 'contactName'
          templateOptions:
            label: $translate.instant('app.dispatch.contact-name')
            placeholder: $translate.instant('app.dispatch.contact-name')
            required: true
        ,
          key: 'type'
          type: 'input'
          name: 'type'
          templateOptions:
            label: $translate.instant('app.dispatch.type')
            placeholder: $translate.instant('app.dispatch.type')
            required: true
        ,
          key: 'unitType'
          type: 'select'
          name: 'unitType'
          model: 'model' 
          defaultValue: 'dispatch_unit'
          templateOptions:
            label: $translate.instant('app.dispatch.unit-type')
            placeholder: $translate.instant('app.dispatch.unit-type')
            required: true
            labelProp: 'label'
            valueProp: 'value'
            options: [
              {
                value: 'unit'
                label: 'Unit'
              },
              {
                value: 'dispatch_unit'
                label: 'Dispatch Unit'
              }
            ]
        ,
          key: 'unit'
          type: 'select'
          name: 'unit'
          templateOptions:
            label: $translate.instant('app.dispatch.unit')
            placeholder: $translate.instant('app.dispatch.unit')
            required: true
            labelProp: 'label'
            valueProp: 'id'
            options: [] # otherwise we get a wonderful console warning
          expressionProperties:
            'templateOptions.options': "model.unitType == 'unit' ? options.data.units : options.data.dispatchUnits"
          data:
            dispatchUnits: DispatchUnitsManager.getFullList({'sort' : 'label,ASC', 'filter[]': 'closedAt,NULL'})
            units: UnitsManager.getFullList({'sort' : 'label,ASC', 'filter[]': 'closedAt,NULL'})
        ,
          key: 'description'
          type: 'textarea'
          name: 'description'
          templateOptions:
            label: $translate.instant('app.dispatch.description')
            placeholder: $translate.instant('app.dispatch.description')
            required: false
            rows: 5

        ]
        form

      FocusManagerBase.getEditForm = (data) ->
        # checkIfBusy = (promise) ->
        #   promise.then (units) ->
        #     # probably wrong but the relations are currently difficult to research
        #     _.each units, (unit, index) ->
        #       unit.task? && unit.task.incident? && unit.task.incident.id? units[index].label += ' (busy)'
        #   promise.$object

        form = [
          key: 'externalId'
          type: 'input'
          name: 'externalId'
          defaultValue: data.lpaPoiOrder.externalId
          templateOptions:
            label: $translate.instant('app.dispatch.task')
            placeholder: $translate.instant('app.dispatch.task')
            required: true
            disabled: true
        ,
          key: 'locationDescription'
          type: 'input'
          name: 'locationDescription'
          defaultValue: data.lpaPoiOrder.locationDescription
          templateOptions:
            label: $translate.instant('app.dispatch.location')
            placeholder: $translate.instant('app.dispatch.location')
            required: true
        ,
          key: 'contactPhoneNumber'
          type: 'input'
          name: 'contactPhoneNumber'
          defaultValue: data.lpaPoiOrder.contactPhoneNumber
          templateOptions:
            label: $translate.instant('app.dispatch.contact-number')
            placeholder: $translate.instant('app.dispatch.contact-number')
            required: true
        ,
          key: 'contactName'
          type: 'input'
          name: 'contactName'
          defaultValue: data.lpaPoiOrder.contactName
          templateOptions:
            label: $translate.instant('app.dispatch.contact-name')
            placeholder: $translate.instant('app.dispatch.contact-name')
            required: true
        ,
          key: 'type'
          type: 'input'
          name: 'type'
          defaultValue: data.lpaPoiOrder.type
          templateOptions:
            label: $translate.instant('app.dispatch.type')
            placeholder: $translate.instant('app.dispatch.type')
            required: true
        ,
          key: 'unitType'
          type: 'select'
          name: 'unitType'
          model: 'model' 
          defaultValue: if data.dispatchUnit? then 'dispatch_unit' else 'unit'
          templateOptions:
            label: $translate.instant('app.dispatch.unit-type')
            placeholder: $translate.instant('app.dispatch.unit-type')
            required: true
            disabled: true
            labelProp: 'label'
            valueProp: 'value'
            options: [
              {
                value: 'unit'
                label: 'Unit'
              },
              {
                value: 'dispatch_unit'
                label: 'Dispatch Unit'
              }
            ]
        ,
          key: 'unit'
          type: 'select'
          name: 'unit'
          defaultValue: if data.dispatchUnit? then data.dispatchUnit.id else data.unit.id
          templateOptions:
            label: $translate.instant('app.dispatch.unit')
            placeholder: $translate.instant('app.dispatch.unit')
            required: true
            disabled: true
            labelProp: 'label'
            valueProp: 'id'
            options: [] # otherwise we get a wonderful console warning
          expressionProperties:
            'templateOptions.options': "model.unitType == 'unit' ? options.data.units : options.data.dispatchUnits"
          data:
            dispatchUnits: DispatchUnitsManager.getFullList({'sort' : 'label,ASC', 'filter[]': 'closedAt,NULL'})
            units: UnitsManager.getFullList({'sort' : 'label,ASC', 'filter[]': 'closedAt,NULL'})
        ,
          key: 'description'
          type: 'textarea'
          name: 'description'
          defaultValue: data.lpaPoiOrder.description
          templateOptions:
            label: $translate.instant('app.dispatch.description')
            placeholder: $translate.instant('app.dispatch.description')
            required: false
            rows: 5

        ]
        form
      
      FocusManagerBase.newPOIOrderFromCoordinates = (scope, coordinates) ->
        errors = []
        progress = false
        
        promise = new Promise (resolve, reject) ->
          geocoding = MundoMap.getLocationFromGeocoding coordinates[0], coordinates[1], $translate.use()
          geocoding.then (locationDescription) ->
            UiHelpers.openDialog
              template: 'lpa/lpa-dispatching/views/task-form.tpl.html'
              title: 'app.dispatch.addPOIOrder'
              progress: progress
              errors: errors
              scope: scope
              data:
                new: true
                model: {}
                basic:
                  fields: FocusManagerBase.getNewFormWithLocationDescription locationDescription
              addPOIOrder: (poiOrder) ->
                @progress = true
                modal = @
                check = modal.data.basic.form.locationDescription.$dirty

                ModelHelpers.verifyLocation check, poiOrder, coordinates
                .then (poiOrder) ->
                  FocusManagerBase.newPOIOrder poiOrder
                  .then (result) ->
                    modal.progress = false
                    resolve(result)
                  , (error) ->
                    modal.progress = false
                    for key, value of error.data.errors.children
                      if value.errors?
                        modal.errors.push
                          key: key
                          value: value.errors

      FocusManagerBase.editPOIOrder = (poiOrder) ->
        errors = []
        progress = false

        oldCoordinates = [
          poiOrder.lpaPoiOrder.locationLongitude,
          poiOrder.lpaPoiOrder.locationLatitude
        ]

        poiOrderId = poiOrder.lpaPoiOrder.id
        
        promise = new Promise (resolve, reject) ->
          UiHelpers.openDialog
            template: 'lpa/lpa-dispatching/views/task-form.tpl.html'
            title: 'app.dispatch.editPOIOrder'
            progress: progress
            errors: errors
            data:
              new: false
              model: {}
              basic:
                fields: FocusManagerBase.getEditForm poiOrder
            updatePOIOrder: (poiOrder) ->
              @progress = true
              modal = @
              check = modal.data.basic.form.locationDescription.$dirty

              ModelHelpers.verifyLocation check, poiOrder, oldCoordinates
              .then (poiOrder) ->
                FocusManagerBase.updatePOIOrder poiOrder, poiOrderId
                .then (result) ->
                  modal.progress = false
                  resolve(result)
                , (error) ->
                  modal.progress = false
                  for key, value of error.data.errors.children
                    if value.errors?
                      modal.errors.push
                        key: key
                        value: value.errors

      FocusManagerBase.getNewFormWithLocationDescription = (locationDescription) ->
        form = FocusManagerBase.getNewForm()
        index = _.indexOf form, _.findWhere(form, { key: "locationDescription" })
        form[index].defaultValue = locationDescription
        form

      FocusManagerBase.newPOIOrder = (poiOrder) ->
        new Promise (resolve, reject) ->
          FocusManagerBase.setUrl("lpa/services/focus/#{poiOrder.unitType}/#{poiOrder.unit}/task_assign/poi_order")
          FocusManagerBase.new poiOrder
          .then (result) ->
            resolve(result)
          , (error) ->
            reject(error)

      FocusManagerBase.updatePOIOrder = (poiOrder, poiOrderId) ->
        new Promise (resolve, reject) ->
          url = "lpa/services/focus/#{poiOrder.unitType}/#{poiOrder.unit}/task_update/poi_order/#{poiOrderId}"
          Restangular.all(url).patch(_.pick(poiOrder, FocusManagerBase.getUpdateObject()))
            .then (result) ->
              resolve(result)
            , (error) ->
              reject(error)
      
      FocusManagerBase.closePOIOrder = (poiOrder) ->
        if poiOrder.dispatchUnit?
          unitType = 'dispatch_unit'
          unitId = poiOrder.dispatchUnit.id
        else
          unitType = 'unit'
          unitId = poiOrder.unit.id
        poiOrder.lpaPoiOrder.closedAt = moment().format()

        new Promise (resolve, reject) ->
          url = "lpa/services/focus/#{unitType}/#{unitId}/task_update/poi_order/#{poiOrder.lpaPoiOrder.id}"
          Restangular.all(url).patch(_.pick(poiOrder.lpaPoiOrder, FocusManagerBase.getUpdateObject()))
            .then (result) ->
              resolve(result)
            , (error) ->
              reject(error)

      FocusManagerBase.getLpaPoiOrderCount = () ->
        new Promise (resolve, reject) ->
          Restangular.all("lpa/tasks/count/poi_order").one($rootScope.user.activeUserContext.tenant.id).get()
          .then (result) ->
            resolve(result)
          , (error) ->
            reject(error)

      FocusManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/tasks/views/task-detail.tpl.html'

      FocusManagerBase.valuePostProcessor = (path, value, row) ->
        if path == 'coordinates'
          lat = row.locationLatitude
          lon = row.locationLongitude
          return "#{lat} , #{lon}"

        return value

      FocusManagerBase.getSchemaLinks = ->
        [
          key: 'label'
          title: 'Tasks'
          multiple: 'tasks'
          hideInList: true
          action: (part) ->
            contactlabel = part.label
            $state.go('tasks.tasks').then(->
              $timeout(->
                $rootScope.$broadcast('searchFor',
                  {term: contactlabel, to: 'app.admin.pageTitles.tasks.tasks'})
              ,100)
            )
        ]

      FocusManagerBase.remapFromRabbit = (data) ->
        poiOrder = data.task

        # _.each wouldn't change the keys...
        Object.keys(poiOrder).forEach (key) =>
          newKey = _.camelCase key
          poiOrder[newKey] = poiOrder[key]
          if newKey != key
            delete poiOrder[key]

        poiOrder.lpaPoiOrder = data.poi_order
        poiOrder.lpaPoiOrder = angular.extend {}, data.poiOrder, poiOrder.lpaPoiOrder

        _.each poiOrder.lpaPoiOrder, (value, key) ->
          newKey = _.camelCase key
          if newKey != key
            delete poiOrder.lpaPoiOrder[key]
          poiOrder.lpaPoiOrder[newKey] = value

        poiOrder.dispatchUnit = data.dispatchUnit

        _.each poiOrder.dispatchUnit, (value, key) ->
          newKey = _.camelCase key
          if newKey != key
            delete poiOrder.dispatchUnit[key]
          poiOrder.dispatchUnit[newKey] = value

        poiOrder.unit = data.unit

        _.each poiOrder.unit, (value, key) ->
          newKey = _.camelCase key
          if newKey != key
            delete poiOrder.unit[key]
          poiOrder.unit[newKey] = value

        poiOrder

      FocusManagerBase
  ]